@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    overflow: scroll;
    font-family: 'Comfortaa';
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

body {
 overflow-x: hidden;
}

.swiper {
    --swiper-navigation-color: #fff !important;
    --swiper-pagination-color: #fff !important;
    --swiper-pagination-bullet-inactive-color: #a5a5a5;
    --swiper-navigation-size: 34px;
    --swiper-pagination-bullet-size: 10px;
    --swiper-pagination-bullet-width: 8px;
    --swiper-pagination-bullet-height: 8px;
    --swiper-pagination-fraction-color: #ffffff !important;
    --swiper-pagination-bottom: 2px;
}

.thumbs .swiper-slide {
    opacity: 0.6;
}

.thumbs .swiper-slide-thumb-active {
    opacity: 1;
}